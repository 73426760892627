// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {
  View,
  FlatList,
  LogBox,
  Alert,
  RefreshControl,
  Platform,
  PermissionsAndroid,
} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {
  LoadingSpinner,
  MySearchbar,
  NavbarWithAction,
} from '../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {deleteUnitLinkById, unlinkUnitLinkById} from '../../../api/links';
import PeopleListItem from '../components/PeopleItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Actionsheet from '../../../components/Actionsheet';
import useDisclose from '../../../components/useDisclose';
import HStack from '../../../components/HStack';
import Box from '../../../components/Box';
import {checkPeopleLinkCode} from '../../../api/connector';
import {deriveDisplayName} from '../../../utils/deriveDisplayName';
import {GetPeople, PeopleSearch, sendLinkingBarCode} from '../peopleReducer';
import {RootStore} from '../../../redux/store';
import {useCallback} from 'react';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import {pasteFromClipboard} from '../../../service/clipboard';
import Container from '../../../components/Container';
import {permissionToBool} from '../../../utils';
import isWeb from '../../../utils/isWeb';
import QRScanner from '../../UnitPeopleLink/components/QRScanner';
import Contacts from 'react-native-contacts';
import {UnitLink} from '../../UnitPeopleLink/types';

const People = ({navigation}) => {
  const currentUnitLink = useSelector(
    (state: RootStore) =>
      state.units.unitLinks.filter(link => link.isDefault === true)[0],
  );
  const {peopleLinks, loading} = useSelector(
    (state: RootStore) => state.people,
  );
  const {user} = useSelector((state: RootStore) => state.auth);

  const [searchTerm, setSearchTerm] = useState('');
  const [isNavbarShown, setIsNavbarShown] = useState(true);

  const [modalVisible, setModalVisible] = useState(false);
  const [linkCodeEntry, setLinkCodeEntry] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();
  const {isOpen, onOpen, onClose} = useDisclose();

  const [sendingBarCode, setSendingBarCode] = useState(false);

  const [loadingContacts, setLoadingContacts] = useState(false);
  const [contacts, setContacts] = useState([]);

  //Place in Redux
  const deletePersonWithId = async (peopleLinkId: string) => {
    try {
      await deleteUnitLinkById(peopleLinkId);
      showSuccessMessage('Person removed from unit');
    } catch (error) {
      showErrorMessage(error.toString());
    }
    dispatch(GetPeople());
  };

  const removePersonFromUnit = async (peopleLinkId: string) => {
    try {
      await unlinkUnitLinkById(peopleLinkId);
      showSuccessMessage('Person unlinked from profile');
    } catch (error) {
      showErrorMessage(error.toString());
    }
    dispatch(GetPeople());
  };

  const ignoreListsWarnings = () => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  };

  useEffect(() => {
    getAllContacts();
  }, []);

  useEffect(() => {
    if (currentUnitLink?.unit?.id) {
      dispatch(GetPeople());
      ignoreListsWarnings();
    }
  }, [currentUnitLink]);

  const showPermissionDialog = () => {
    Alert.alert(
      'Alert',
      'Please grant Contact permission in settings or skip to continue.',
      [
        {
          text: 'SKIP',
          onPress: () => navigation.navigate('User Credentials'),
        },
        {
          text: 'OK',
          onPress: () => {
            Linking.openSettings();
          },
        },
      ],
    );
  };

  const saveContact = async (unitPeopleLink: UnitLink) => {
    const newContact = {
      emailAddresses: [
        {
          label: 'home',
          email: unitPeopleLink.person.email
            ? unitPeopleLink.person.email
            : unitPeopleLink.email
            ? unitPeopleLink.email
            : '',
        },
      ],
      phoneNumbers: [
        {
          label: 'mobile',
          number: unitPeopleLink.person.phone
            ? unitPeopleLink.person.phone
            : unitPeopleLink.phone
            ? unitPeopleLink.phone
            : '',
        },
      ],
      familyName: unitPeopleLink.person.lastName
        ? unitPeopleLink.lastName
        : unitPeopleLink.lastName,
      givenName: unitPeopleLink.person.firstName
        ? unitPeopleLink.firstName
        : unitPeopleLink.firstName,
    };
    const _contacts = await Contacts.getAll();
    for (let index = 0; index < _contacts.length; index++) {
      const element = _contacts[index];
      if (
        element.givenName + element.familyName ===
        newContact.givenName + newContact.familyName
      ) {
        //Check if phone and email are changed and then update accordingly
        if (
          element.phoneNumbers[0]?.number !==
            newContact.phoneNumbers[0]?.number ||
          element.emailAddresses[0]?.email !==
            newContact.emailAddresses[0]?.email
        ) {
          element.phoneNumbers[0] = newContact.phoneNumbers[0];
          element.emailAddresses[0] = newContact.emailAddresses[0];
          await Contacts.updateContact(element);
          showSuccessMessage('Contact updated sucessfully');
        }
        return;
      }
    }

    const contact = await Contacts.addContact(newContact);
    if (contact) {
      showSuccessMessage('Contact added to phone book sucessfully');
    }
  };

  const getAllContacts = async () => {
    if (Platform.OS === 'android') {
      try {
        const writePermission = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.WRITE_CONTACTS,
          {
            title: 'Contacts',
            message: 'This app would like to view your contacts.',
            buttonPositive: 'Accept',
          },
        );
        const readPermission = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
        );
        if (writePermission === 'granted' && readPermission === 'granted') {
          const allContacts = await Contacts.getAll();
          setContacts(allContacts);
        } else if (
          writePermission === 'never_ask_again' ||
          readPermission === 'never_ask_again'
        ) {
          showPermissionDialog();
        }
      } catch (e) {
        showErrorMessage('Pemission error' + e);
      }
    }
    if (Platform.OS === 'ios') {
      const permission = await Contacts.checkPermission();
      if (permission === 'undefined') {
        const requestedPermission = await Contacts.requestPermission();
        if (requestedPermission === 'authorized') {
          const allContacts = await Contacts.getAll();
          setContacts(allContacts);
        }
        if (requestedPermission === 'denied') {
          showErrorMessage(
            'Contact permission denied, please enable in settings',
          );
          showPermissionDialog();
        }
      }
      if (permission === 'authorized') {
        const allContacts = await Contacts.getAll();
        setContacts(allContacts);
      }
      if (permission === 'denied') {
        showErrorMessage(
          'Contact permission denied, please enable in settings',
        );
        showPermissionDialog();
      }
    }
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    dispatch(GetPeople());
    setRefreshing(false);
  }, []);

  const showPrompt = (id: string, toDelete: boolean) => {
    Alert.alert(
      '',
      `Do you really want to ${toDelete ? 'delete' : 'unlink'} this person  ?`,
      [
        {
          text: 'NO',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'YES',
          onPress: () =>
            toDelete ? deletePersonWithId(id) : removePersonFromUnit(id),
        },
      ],
      {
        cancelable: true,
      },
    );
  };

  const checkPeopleLinkCodeValidity = async () => {
    if (linkCodeEntry?.startsWith('UC')) {
      checkUnitCodeValidity();
      return;
    }
    try {
      const connector = await checkPeopleLinkCode(linkCodeEntry);
      if (connector) {
        navigation.navigate('Link person to unit', {
          isFromNotificationTray: false,
          peopleId: connector.person.id,
          unitId: currentUnitLink.unit.id,
          isDirectLink: true,
        });
      } else {
        showErrorMessage(
          "We couldn't find that people code. People codes are case sensitive. Please try again",
        );
      }
    } catch (error) {
      showErrorMessage('Link code not found' + error.message);
    } finally {
      setLinkCodeEntry(null);
    }
  };

  const fetchCopiedText = async () => {
    const text = await pasteFromClipboard();
    setLinkCodeEntry(text);
  };

  const checkUnitCodeValidity = async () => {
    navigation.navigate('Add with UnitCode', {
      profileLinkCode: linkCodeEntry,
    });
  };

  const sendBarCode = async unitPeopleLinkId => {
    setSendingBarCode(true);
    const res = await sendLinkingBarCode(unitPeopleLinkId);
    showSuccessMessage('QR Code Sent Successfully');
    setSendingBarCode(false);
  };

  const MainComponent = () => {
    return (
      <>
        {loading || loadingContacts ? (
          <LoadingSpinner />
        ) : (
          <>
            <FlatList
              refreshControl={
                <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
              }
              data={peopleLinks}
              style={{marginBottom: Platform.OS === 'ios' ? 50 : 0}}
              showsVerticalScrollIndicator={false}
              ListFooterComponent={<View style={tailwind('h-24')} />}
              renderItem={({item}) => (
                <PeopleListItem
                  key={item?.person?.id}
                  unitPeopleLink={item}
                  onSelectDelete={() => showPrompt(item.id, true)}
                  onSelectEdit={() =>
                    navigation.navigate('Edit Person Link', {
                      unitLink: item,
                    })
                  }
                  currentUser={user.id}
                  itemClickHandler={() =>
                    navigation.navigate('Person Details', {
                      personId: item.person.id,
                    })
                  }
                  openCalendar={() => {
                    navigation.navigate('Unit Calendar', {
                      unitId: item.unit.id,
                      unitPeopleLinkId: item.id,
                      isLoggedInUser: item.person.id === user.id,
                      personId: item.person.id,
                      personName: deriveDisplayName(item),
                      unitName: item.unitNickname
                        ? item.unitNickname
                        : item.unit.unitName,
                      allowedToday: item.dateFrameDay.allowedToday,
                      allowedTommorow: item.dateFrameDay.allowedTomorrow,
                    });
                  }}
                  onClickApprovalsRequired={() => {
                    navigation.navigate('Approvals History', {
                      personName: `${deriveDisplayName(item)}`,
                      isLoggedInUser: item.person.id === user.id,
                      approvals: item.approvals,
                      type: 'pending',
                      recordName: item.unitNickname
                        ? item.unitNickname
                        : item.unit.unitName,
                    });
                  }}
                  openPermissions={() => {
                    /**
                     * If person is trying to edit their own permissions,
                     * check if canManagePeople and canEditSelf
                     *
                     * if person is trying to edit others permissions,
                     * check if canManagePeople and residentCanAdd
                     *
                     */
                    const isCurrentUser = user.id === item?.person?.id;
                    const canEditOtherPeoplePermissions =
                      !isCurrentUser &&
                      currentUnitLink?.canManagePeople &&
                      item?.peopleType?.residentCanAdd;

                    const canEditOwnPermissions =
                      isCurrentUser && item.canEditSelf;

                    navigation.navigate('User Permissions', {
                      isLoggedInUser: item.person.id === user.id,
                      unitPeopleLink: item,
                      canEditOtherPeoplePermissions:
                        canEditOtherPeoplePermissions,
                      canEditOwnPermissions: canEditOwnPermissions,
                    });
                  }}
                  onSelectEditOwnUnitLink={() => {
                    navigation.navigate(
                      E_NAVIGATION_DESTINATIONS.EDIT_OWN_UNITLINK,
                      {
                        unitPeopleLink: item,
                        from: 'PEOPLE',
                      },
                    );
                  }}
                  sendBarCode={() => sendBarCode(item.id)}
                  onAddToContacts={() => saveContact(item)}
                />
              )}
            />
            <Actionsheet isOpen={isOpen} onClose={onClose}>
              <Actionsheet.Content>
                <Box w="100%" h={60} px={4}>
                  <HStack>
                    <Text style={tailwind('flex-1 mt-2')}>Select option :</Text>
                  </HStack>
                </Box>
                <Actionsheet.Item
                  onPress={() => {
                    onClose();
                    setModalVisible(true);
                  }}>
                  Add people with Link Code from person
                </Actionsheet.Item>
                <Actionsheet.Item
                  onPress={() => {
                    onClose();
                    navigation.navigate('Create People and Link', {
                      unitId: currentUnitLink.unit.id,
                    });
                  }}>
                  Manually add people information
                </Actionsheet.Item>
              </Actionsheet.Content>
            </Actionsheet>
          </>
        )}
      </>
    );
  };

  return (
    <Container>
      <>
        {isNavbarShown ? (
          <NavbarWithAction
            hasSearch={peopleLinks.length > 0}
            navigation={navigation}
            title="People"
            showCurrentUnit
            onClickSearch={() => setIsNavbarShown(false)}
            hasAdd={
              !loading &&
              permissionToBool(currentUnitLink?.peoplePermission?.charAt(3))
            }
            onClickAdd={() => onOpen()}
            hasAccessCard
            onClickAccessCard={() =>
              navigation.navigate(E_NAVIGATION_DESTINATIONS.ID_CARD)
            }
            fullScreen
          />
        ) : (
          <MySearchbar
            placeholder="Type firstname, lastname, middlename, nickname"
            value={searchTerm}
            setValue={(val: string) => {
              setSearchTerm(val);
              dispatch(PeopleSearch(val));
            }}
            resetSearch={() => {
              setIsNavbarShown(true);
              setSearchTerm('');
              dispatch(PeopleSearch(''));
            }}
          />
        )}
      </>
      <MainComponent />
      <Modal
        isOpen={modalVisible}
        onClose={setModalVisible}
        size="xl"
        style={{
          marginTop: Platform.OS === 'ios' ? -100 : 0,
        }}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Enter Link Code</Modal.Header>
          <Modal.Body
            _scrollview={{
              keyboardShouldPersistTaps: 'handled',
            }}>
            <Text>Type in/paste </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <View
                style={{
                  flex: 3,
                }}>
                <Input
                  mt={4}
                  mb={4}
                  placeholder=""
                  value={linkCodeEntry}
                  onChangeText={text => setLinkCodeEntry(text)}
                  style={{
                    fontFamily: 'RobotoMono-Regular',
                    fontSize: 24,
                    textAlign: 'center',
                  }}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  marginLeft: 10,
                }}>
                <Button py={3} onPress={fetchCopiedText}>
                  {'Paste '}
                </Button>
              </View>
            </View>
            <Text style={{marginBottom: 6, textAlign: 'center'}}>or </Text>
            {!isWeb && (
              <QRScanner
                setProfileCode={(qrCode: string) => setLinkCodeEntry(qrCode)}
              />
            )}
            <Button
              onPress={() => {
                setModalVisible(!modalVisible);
                checkPeopleLinkCodeValidity();
              }}
              mt={4}>
              Continue
            </Button>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </Container>
  );
};

export default People;
