import React, {useCallback, useEffect} from 'react';
import {useState} from 'react';
import {FlatList, Pressable, View} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {
  EmptyList,
  LoadingSpinner,
  NavbarWithAction,
  RequireApproval,
} from '../../../components';
import {
  showErrorMessage,
  showInformationMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {
  dateFrameRequestAccess,
  deleteUnitLinkDateFrame,
  fetchDateFrameByPerson,
  fetchDateFramesByUnit,
} from '../../../api/dataframe';
// import {Calendar} from 'react-native-calendars';
// import Calendar from 'react-calendar';
import {
  convertDateFrameToDays,
  markCalendarDays,
} from '../../../utils/dateFrames';
import {Card} from 'react-native-paper';
import {useFocusEffect} from '@react-navigation/core';
import CreateDateFramePanel from '../../../components/CreateDateFramePanel';
import {CalendarDateFrame, DateFrame} from '../../../@types/DateFrame';
import {toLocalDateTime} from '../../../utils';
import {SYSTEM_FOREVER_DATEFRAME_ID} from '../../../constants';
import {showAlert} from '../../../service';
import Button from '../../../components/Button';
import IconButton from '../../../components/IconButton';
import HStack from '../../../components/HStack';
import Menu from '../../../components/Menu';
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';
import dayjs from 'dayjs';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import Legend from '../components/Legend';
import CustomCalendar from '../components/CustomCalendar';

const UnitCalendar = ({navigation, route}) => {
  const {unitId, unitPeopleLinkId, unitName, isLoggedInUser, personName} =
    route.params;
  const TITLE = `${
    isLoggedInUser ? 'Your' : personName
  } access for unit ${unitName}`;
  const [dateFrames, setDateFrames] = useState<Array<CalendarDateFrame>>([]);
  const [markedDates, setmarkedDates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDateFrame, setSelectedDateFrame] = useState('');
  const [panelActive, setPanelActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasForeverDateframe, setHasForeverDateFrame] = useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [editModeInitialState, setEditModeInitialState] = React.useState(null);
  const [loadingUnitDateFrames, setLoadingUnitDateFrames] = useState(false);
  const [unitDateFrames, setUnitDateFrames] = useState<Array<DateFrame> | []>(
    [],
  );

  useEffect(() => {
    fetchDateFrames(unitPeopleLinkId);
  }, [unitPeopleLinkId]);

  useFocusEffect(
    useCallback(() => {
      fetchDateFrames(unitPeopleLinkId);
    }, [unitPeopleLinkId]),
  );

  async function fetchDateFrames(linkId) {
    setIsLoading(true);
    try {
      let results: Array<CalendarDateFrame> = await fetchDateFrameByPerson(
        1,
        linkId,
      );

      let foreverDateFrame = results.find(
        val => val.dateFrame.id === SYSTEM_FOREVER_DATEFRAME_ID,
      );

      if (foreverDateFrame) {
        setHasForeverDateFrame(true);
        setDateFrames(results);
        setIsLoading(false);
      } else {
        setHasForeverDateFrame(false);
        const dateFramesInUnit = results.map(item => {
          return {
            ...item,
            days: convertDateFrameToDays(
              new Date(item.dateFrame.startDate),
              new Date(item.dateFrame.endDate),
            ),
          };
        });
        const marked = markCalendarDays(dateFramesInUnit);
        setmarkedDates(marked);
        setDateFrames(dateFramesInUnit);
        setIsLoading(false);
      }
    } catch (error) {
      showErrorMessage(error.message);
    }
  }

  const fetchUnitDateFrames = async (unitId: string) => {
    setLoadingUnitDateFrames(true);
    try {
      const res = await fetchDateFramesByUnit(1, unitId);
      setUnitDateFrames(res);
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setLoadingUnitDateFrames(false);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const dateframe = dateFrames.find(
      item => item.dateFrame.id === selectedDateFrame,
    );

    if (dateframe) {
      showInformationMessage('Person already added to this dateframe.');
      setIsSubmitting(false);
      return;
    }

    const dateframeData = {
      unitId,
      startDate: toLocalDateTime(new Date(dateframe.dateFrame.startDate), true),
      endDate: toLocalDateTime(new Date(dateframe.dateFrame.endDate), false),
      description: dateframe.dateFrame.shortDescription,
    };

    try {
      await dateFrameRequestAccess(dateframeData);
      showSuccessMessage('Dateframe added successfully');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    }
    setIsSubmitting(false);
  };

  const handleDeletePress = (unitlinkdateFrameId: string) => {
    showAlert(
      'Confirm',
      'Are you sure you want to unlink this dateframe from user ?',
      () => console.log('NO'),
      async () => {
        try {
          await deleteUnitLinkDateFrame(unitlinkdateFrameId);
          showSuccessMessage('Dateframe unlinked from person');
          await fetchDateFrames(unitPeopleLinkId);
        } catch (error) {
          showErrorMessage(error.message);
        }
      },
    );
  };




  const renderHeader = () => {
    const dateStyles = {
      "2024-09-16": {
        "selected": true,
        "selectedColor": "grey",
        "textColor": "white"
      },
      "2024-09-17": {
        "selected": true,
        "selectedColor": "grey",
        "textColor": "white"
      }
    };

    return (
      <div style={{}}>
        <CustomCalendar markedDates={markedDates} />
        <Legend />
      </div>
    );
  };


  return (
    <>
    <Container>
      <NavbarWithAction
        hasAdd={!hasForeverDateframe}
        hasBackArrow
        navigation={navigation}
        onClickAdd={() => {
          setEditMode(false);
          setModalVisible(true);
          fetchUnitDateFrames(unitId);
        }}
        hasSearch={false}
        title={TITLE}
        hasMoreMenu={false}
        hasProfile={false}
        hasSignOut={false}
        fullScreen
      />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <FlatList
            data={dateFrames}
            ListEmptyComponent={<EmptyList message="No dateframes found" />}
            renderItem={({item}) => (
              <DateFrameItem
                dateFrame={item}
                onClickApprovalsRequired={() => {
                  navigation.navigate('Approvals History', {
                    personName: 'Dateframe',
                    isLoggedInUser: false,
                    approvals: item.approvals,
                    type: 'pending',
                    recordName: item.dateFrame.shortDescription
                      ? item.dateFrame.shortDescription
                      : item.dateFrame.notes,
                  });
                }}
                handleDelete={() => handleDeletePress(item.id)}
                handleEdit={() => {
                  setEditModeInitialState(item);
                  setEditMode(true);
                  setPanelActive(true);
                }}
              />
            )}
            ListHeaderComponent={hasForeverDateframe ? <></> : renderHeader()}
          />
        )}
      <Modal isOpen={modalVisible} onClose={setModalVisible} size="xl">
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Link dateframe to person</Modal.Header>
          <Modal.Body>
            <Text style={tailwind('mb-2 flex-1')}>
              Select a Date frame or create a new one
            </Text>
            <HStack style={tailwind('flex-1 mt-3')}>
              {loadingUnitDateFrames ? (
                <HStack>
                  <LoadingSpinner size="small" />
                  <Text style={{fontSize: 12, marginTop: 12, marginLeft: 12}}>
                    Loading dateframes...
                  </Text>
                </HStack>
              ) : (
                <Select
                  placeholder="Select dateframe"
                  py="4"
                  minWidth={200}
                  style={{flex: 1}}
                  selectedValue={selectedDateFrame}
                  onValueChange={val => {
                    setSelectedDateFrame(val);
                  }}>
                  {unitDateFrames.map((item: DateFrame) => (
                    <Select.Item
                      label={item.shortDescription}
                      value={item.id}
                      key={item.id}
                    />
                  ))}
                </Select>
              )}
              <Button
                style={{flex: 1}}
                ml={4}
                leftIcon={<FeatherIcon name="plus" size={20} />}
                onPress={() => {
                  setModalVisible(false);
                  setPanelActive(true);
                }}
                variant="outline">
                Create new
              </Button>
            </HStack>
            <Button
              isDisabled={isSubmitting || !selectedDateFrame}
              my="4"
              py="4"
              onPress={handleSubmit}>
              Submit
            </Button>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      </Container>
      <CreateDateFramePanel
        editMode={editMode}
        initialState={editModeInitialState}
        unitId={unitId}
        unitPeopleLinkId={unitPeopleLinkId}
        navigation={navigation}
        panelActive={panelActive}
        setPanelActive={(state: boolean) => setPanelActive(state)}
        onSubmitSuccess={() => fetchDateFrames(unitPeopleLinkId)}
        isLoggedInUser={isLoggedInUser}
      />
    </>
  );
};

const DateFrameItem = ({
  dateFrame,
  onClickApprovalsRequired,
  handleDelete,
  handleEdit,
}) => {
  return (
    <Card style={tailwind('rounded my-2 p-2 mx-2')}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <View>
          <Text style={tailwind('text-lg')}>
            {dateFrame.dateFrame.shortDescription
              ? dateFrame.dateFrame.shortDescription
              : dateFrame.dateFrame.notes}
          </Text>

          <Text style={tailwind(' text-xs')}>
            {new Date(dateFrame.dateFrame.startDate).toLocaleString()} -{' '}
            {new Date(dateFrame.dateFrame.endDate).toLocaleString()}
          </Text>
          <RequireApproval
            approvals={dateFrame.approvals}
            onClickApprovalsRequired={() => onClickApprovalsRequired()}
          />
        </View>
        <Menu
          placement="right top"
          trigger={triggerProps => {
            return (
              <Pressable {...triggerProps} style={tailwind('pl-4')}>
                <IconButton icon="ellipsis-vertical" size={22} />
              </Pressable>
            );
          }}>
          <Menu.Item onPress={() => handleEdit()}>Edit</Menu.Item>
          <Menu.Item onPress={() => handleDelete()}>Delete</Menu.Item>
        </Menu>
      </View>
    </Card>
  );
};

export default UnitCalendar;
