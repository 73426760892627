// @ts-nocheck
import React, {useState} from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {View, ScrollView} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import {Navbar} from '../../../components';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {Card} from 'react-native-paper';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import VStack from '../../../components/VStack';
import {
  checkUnitPeopleCode,
  linkPersonToUnitLink,
} from '../../People/peopleReducer';
import QRScanner from '../components/QRScanner';
import {useRoute} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import {GetUnitScreenData} from '../../Units/unitsReducer';
import {HStack} from 'native-base';
import {pasteFromClipboard} from '../../../service/clipboard';
import isWeb from '../../../utils/isWeb';
import Container from '../../../components/Container';
import FormContainer from '../../../components/FormContainer';
import {checkPeopleLinkCode} from '../../../api/connector';

const AddWithUnitCode = ({navigation}) => {
  const route = useRoute();
  //@ts-ignore
  const profileLinkCode = route?.params?.profileLinkCode
    ? route?.params?.profileLinkCode
    : '';
  const currentUnitLink = useSelector(
    (state: RootStore) =>
      state.units.unitLinks.filter(link => link.isDefault === true)[0],
  );
  const [personShown, setPersonShown] = useState(false);
  const [profileCode, setProfileCode] = useState(profileLinkCode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [person, setPerson] = useState(null);
  const [isSubmittingLink, setIsSubmittingLink] = useState(false);

  const dispatch = useDispatch();

  /**
   * Person requesting to be linked to a UnitPeopleLinkProfile
   * personId is current user(filled by backend)
   * unitPeopleLinkId(unitPeopleLink.id/ person.id)
   * @param person
   */
  const updateLink = async person => {
    try {
      const data = {
        unitPeopleLinkId: person.id,
      };
      //When linking as an owner, we want to popup allowAutomatedCalls dialog.

      await linkPersonToUnitLink(data);
      dispatch(GetUnitScreenData());
      showSuccessMessage('Link request sent');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  const checkUnitCodeValidity = async () => {
    if (profileCode?.startsWith('PC')) {
      checkPeopleCodeValidity();
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await checkUnitPeopleCode(profileCode);
      if (res) {
        setPerson(res);
        setPersonShown(true);

        setIsSubmittingLink(true);
        updateLink(res).then(() => {
          setIsSubmittingLink(false);
        });
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchCopiedText = async () => {
    const text = await pasteFromClipboard();
    setProfileCode(text);
  };

  const checkPeopleCodeValidity = async () => {
    setIsSubmitting(true);
    try {
      const connector = await checkPeopleLinkCode(profileCode);
      if (connector) {
        navigation.navigate('Link person to unit', {
          isFromNotificationTray: false,
          peopleId: connector.person.id,
          unitId: currentUnitLink.unit.id,
          isDirectLink: true,
        });
      }
    } catch (error) {
      showErrorMessage('Link code not found' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const PeopleItem = ({person}) => {
    return (
      <Card style={tailwind('rounded my-2')}>
        <View style={tailwind('flex-row p-2 justify-between items-center')}>
          <VStack>
            <Text style={tailwind('text-lg')}>
              {person.personName ? person.personName : 'No name'}
            </Text>
            <Text style={tailwind('p-2')}>{person.peopleType}</Text>
            <Text style={tailwind('p-2')}>
              {`Link status-${
                person.linkApproved ? person.linkApproved : 'Unknown'
              }`}
            </Text>
          </VStack>
          {person.isAvailable ? (
            <Button isLoading={isSubmittingLink} isLoadingText="Submitting">
              Link
            </Button>
          ) : (
            <Text>Already used</Text>
          )}
        </View>
      </Card>
    );
  };

  return (
    <Container hasDrawer={false}>
      <Navbar navigation={navigation} title="Add with unit code" />
      <FormContainer>
        <ScrollView style={{flex: 1, marginHorizontal: 16}}>
          {personShown ? (
            <>
              <View style={tailwind('flex-row')}>
                <Ionicons
                  name="checkmark-circle-outline"
                  color="#47B275"
                  size={24}
                />
                <Text style={tailwind('mb-4 ml-2')}>
                  Code entered successfully .
                </Text>
              </View>
              {person && <PeopleItem person={person} key={person.id} />}
              <View style={tailwind('h-24')} />
            </>
          ) : (
            <>
              <Text style={tailwind('mt-1')}>Type the code:</Text>
              <Input
                mt={4}
                mb={4}
                value={profileCode}
                onChangeText={text => setProfileCode(text)}
                InputRightElement={
                  <Button
                    variant="ghost"
                    startIcon={
                      <MaterialCommunityIcons
                        name="content-paste"
                        color="gray"
                        size={24}
                      />
                    }
                    onPress={fetchCopiedText}>
                    Paste
                  </Button>
                }
                style={{
                  // fontFamily: 'RobotoMono-Regular',
                  fontSize: 24,
                  textAlign: 'center',
                }}
              />
              <Text style={tailwind('my-4 text-center')}>or</Text>
              {!isWeb && (
                <QRScanner
                  setProfileCode={(qrCode: string) => {
                    setProfileCode(qrCode);
                  }}
                />
              )}
              <Text style={tailwind('text-xl mt-12')}>
                This code is typically given to management office or a resident
                to link other smart people who have the app to a unit.
              </Text>
              <Text style={tailwind('text-xl mt-1')}>
                Use the Generate Link Code in the Others page to generate a
                code.
              </Text>
            </>
          )}
        </ScrollView>
        {!personShown && (
          <HStack>
            <Button
              onPress={() => checkUnitCodeValidity()}
              style={tailwind('mx-4 mb-24 flex-1')}
              py="4"
              isLoading={isSubmitting}
              isLoadingText="Submitting ...">
              Submit
            </Button>
          </HStack>
        )}
      </FormContainer>
    </Container>
  );
};

export default AddWithUnitCode;
