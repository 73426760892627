// @ts-nocheck
import {Text} from 'react-native';
import tailwind from 'tailwind-rn';
import React from 'react';
import getConfig from '../../../utils/getConfig';

export default () => {
  const environment = getConfig('MY_WORKS_ENVIRONMENT');
  const apiVersion = getConfig('MY_WORKS_API_SUFFIX');
  
  return (
    <Text style={[tailwind('text-center text-xs'), {color: '#718096'}]}>
      {`V1.0.17-09.02.2025 ${environment} ${apiVersion}`}
    </Text>
  );
};